const Leadership = () => {
  return (
    <div className="leadership">
      <div className="leadership__wrapper">
        <p>
          <strong>Dear Valued Client,</strong>
          <br />
          <br />
          Welcome to Pro Legal Group, a home of dynamic, innovative, and elegant
          legal solutions! <br />
          I am Edgar Beniaminov, global legal counsel backed by 15 years of
          professional expertise and trust gained with high profile clients and
          partners portfolio worldwide. <br />
          My commitment to delivering exceptional client experiences has
          resulted in the cultivation of enduring stakeholder relationships.
        </p>
        <div className="leadership__history">
          <div className="leadership__year">
            <h3>2008</h3>
            <p>
              KPMG <br />
              <strong>Office of General Counsel</strong>
            </p>
          </div>
          <div className="leadership__year">
            <h3>2017</h3>
            <p>
              Family Office (UHNWI)
              <br />
              <strong>Head of Legal</strong>
            </p>
          </div>
          <div className="leadership__year">
            <h3>2023</h3>
            <p>
              International Financial and Investment Company
              <br />
              <strong>General Counsel</strong>
            </p>
          </div>
        </div>

        <p className="leadership__text">
          As a Legal Counsel, I possess an extensive command of international
          business law, contract law, intellectual property law, private client
          & family office services. I command legal expertise in both Russian
          and English law. Proficiency in three languages - Russian, English,
          and Armenian - enables me to communicate effectively across diverse
          cultural and linguistic landscapes.
        </p>

        <p className="leadership__text">
          It is my distinct pleasure to offer my clients a globally informed
          legal guidance that empowers them to enhance their lives, businesses,
          and wealth. In collaboration with the adept team at Pro Legal Group
          together with our well-established global network of esteemed
          consultants and premier service providers , we are honored to provide
          you with unparalleled client services, adding substantial value to
          your endeavors. 
        </p>
        <p className="leadership__text">
          <strong>
            Respectfully Yours, <br />
            Edgar Beniaminov  <br />
            Founder & CEO
          </strong>
        </p>
      </div>
    </div>
  );
};

export { Leadership };
